import Menu from './components/menu';
import InView from './components/in-view';
import { accordion } from './components/accordion';


const menuElement = document.querySelector( '[data-menu]' );
const inView = new InView( '[data-in-view]' );
const Accordions = document.querySelectorAll( '[data-accordion]' );


if ( menuElement ) {
    const menu = new Menu( menuElement );
}

if ( Accordions.length > 0 ) {
    Accordions.forEach(element => {
        accordion.init( element );
    })
}





