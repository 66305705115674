import "wicg-inert";

export default class Menu {

    constructor( element ) {
        this.element = element;
        
        this.hamburger = document.querySelector('[data-hamburger]');
        this.close = document.querySelector('[data-close-sr]');

        this.header = document.querySelector('header');

        this.firstNavElement = document.querySelector('#menu-item-36 a');
        this.lastNavElement = document.querySelector('[data-nav-last]');
        
        this.hamburger.addEventListener( 'click', ( event ) => { 
            event.stopImmediatePropagation();
            this.toggleMenu(); 
        } )
        this.close.addEventListener( 'click', ( event ) => { 
            event.stopImmediatePropagation();
            this.toggleMenu(); 
        } )

        this.firstNavElement.addEventListener('keydown', (event) => { this.moveFocusToBottom(event); });
        this.lastNavElement.addEventListener('keydown', (event) => { this.moveFocusToTop(event); });
        this.activeMenuWidth = window.matchMedia("(max-width: 57.5rem)");
        
        this.makeInertWhenActive(this.activeMenuWidth);

        this.activeMenuWidth.addEventListener('change', (event) => {
            this.makeInertWhenActive(this.activeMenuWidth);
        })

        this.element.addEventListener('transitionend', (event) => {
            this.element.classList.remove('nav-transition');
        })

    }

    toggleMenu() {
        if (this.hamburger.getAttribute('aria-expanded') === 'true') {
            this.hideMenu();
        } else {
          
            this.showMenu();
        }
    }

    showMenu() {
        console.log('show', this.element.classList);
        this.hamburger.setAttribute('aria-expanded', 'true');
        this.close.setAttribute('aria-expanded', 'true');
        this.header.classList.add('menu-open');
        this.element.classList.add('visible');
        this.element.inert = false;
        this.element.classList.add('nav-transition');
    }

   hideMenu() {
        console.log('hide', this.element.classList);

        this.hamburger.setAttribute('aria-expanded', 'false');
        this.close.setAttribute('aria-expanded', 'false');
        this.header.classList.remove('menu-open');
        this.element.classList.remove('visible');
        this.element.inert = true;
        this.element.classList.add('nav-transition');
    }

    moveFocusToTop(e) {
        if (e.key === "Tab" && !e.shiftKey) {
            e.preventDefault();
            this.firstNavElement.focus({ focusVisible: true });
            // this.firstNavElement.setAttribute('tabindex', 0);

            console.log('movefocustotop', this.firstNavElement);
        }
    }

    moveFocusToBottom(e) {
        if (e.key === "Tab" && e.shiftKey) {
            e.preventDefault();
            this.lastNavElement.focus({ focusVisible: true });
            // this.lastNavElement.setAttribute('tabindex', 0);
        }
    }

    makeInertWhenActive(x){
        if (x.matches) {
            this.element.inert = true;
        } else {
            this.element.inert = false;
        }
    }
    
}

