class Accordion {

    init(element) {
        this.buttonNodes = element.querySelectorAll('[data-accordion-button]');

            this.buttonNodes.forEach( (buttonNode) => {

            var controlledNode = false;
        
            var id = buttonNode.getAttribute('aria-controls');
        
            if (id) {
              var controlledNode = document.getElementById(id);
            }
        
            buttonNode.setAttribute('aria-expanded', 'false');
            this.hideContent(controlledNode);
        
            buttonNode.addEventListener('click', (event) => { 
              // event.stopPropagation();
              // event.preventDefault();
              event.stopImmediatePropagation();

              this.onClickAction(buttonNode, controlledNode)} );
            buttonNode.addEventListener('focus', (event) => { this.onFocus(buttonNode)} );
            buttonNode.addEventListener('blur', (event) => { this.onBlur(buttonNode)} );
        })

        
      }

    showContent(controlledNode) {
        if (controlledNode) {
          controlledNode.style.display = 'block';
        }
    }

    hideContent(controlledNode) {
        if (controlledNode) {
          controlledNode.style.display = 'none';
        }
      }


    toggleExpand(buttonNode, controlledNode) {
      console.log(buttonNode);
        if (buttonNode.getAttribute('aria-expanded') === 'true') {
          console.log('true', buttonNode);

          buttonNode.setAttribute('aria-expanded', 'false');
          this.hideContent(controlledNode);
        } else {
          console.log('false', buttonNode);

          buttonNode.setAttribute('aria-expanded', 'true');
          this.showContent(controlledNode);
        }
      }

      onClickAction(buttonNode, controlledNode) {
        this.toggleExpand(buttonNode, controlledNode);
      }
    
      onFocus(buttonNode) {
        buttonNode.classList.add('focus');
      }
    
      onBlur(buttonNode) {
        buttonNode.classList.remove('focus');
      }

}

export const accordion = new Accordion();